var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      ref: "drawerRef",
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "nopermission",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "template",
        { slot: "other-region" },
        [
          _c(
            "a-steps",
            {
              staticClass: "mb-6",
              attrs: {
                current: _vm.statusMap[_vm.formData.status] + 1,
                size: "small",
                status: _vm.formData.rejectType != 0 ? "error" : "process",
              },
            },
            [
              _c("a-step", { attrs: { title: "自动推送" } }),
              _c("a-step", {
                attrs: {
                  title:
                    _vm.formData.status == "BOSS_REJECTED"
                      ? "BOSS已驳回"
                      : "HR确认",
                },
              }),
              _c("a-step", { attrs: { title: "BOSS确认" } }),
              _c("a-step", { attrs: { title: "人员确认" } }),
              _c("a-step", { attrs: { title: "支付" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {},
        [
          _c("info-row", { attrs: { label: "月份" } }, [
            _vm._v(_vm._s(_vm.formData.month)),
          ]),
          _c("info-row", { attrs: { label: "人员" } }, [
            _vm._v(_vm._s(_vm.formData.nickName)),
          ]),
          _c("info-row", { attrs: { label: "金额" } }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.formData.monies &&
                    _vm.formData.monies
                      .map((item) => {
                        return item.money + _vm.moneyTypeMap[item.moneyType]
                      })
                      .join("  ")
                ) +
                "\n    "
            ),
          ]),
          _vm.formData.status == "HR_CONFIRMED" ||
          _vm.formData.status == "BOSS_REJECTED"
            ? _c(
                "info-row",
                { attrs: { label: "备注", wrapCol: "19" } },
                [
                  _c("a-textarea", {
                    attrs: { autoSize: "" },
                    model: {
                      value: _vm.formData.bossComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "bossComment", $$v)
                      },
                      expression: "formData.bossComment",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "info-row",
            { attrs: { label: "历史记录" } },
            [
              _c(
                "a-timeline",
                { staticStyle: { "margin-top": "6px", width: "140%" } },
                _vm._l(_vm.logList, function (item, index) {
                  return _c(
                    "a-timeline-item",
                    { key: index, staticStyle: { "font-size": "14px" } },
                    [
                      _vm._v(
                        "（" +
                          _vm._s(item.operationTime) +
                          "）" +
                          _vm._s(item.userName) +
                          _vm._s(" ") +
                          " " +
                          _vm._s(item.content)
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "statistics:settlement:reject",
                      expression: "'statistics:settlement:reject'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger" },
                  on: { click: _vm.rejectRating },
                },
                [_vm._v("驳回")]
              ),
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "statistics:settlement:confirm",
                      expression: "'statistics:settlement:confirm'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRating },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "月份", prop: "month" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入月份", disabled: true },
                    model: {
                      value: _vm.formData.month,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "month", $$v)
                      },
                      expression: "formData.month",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "人员", prop: "nickName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入人员", disabled: true },
                    model: {
                      value: _vm.formData.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "nickName", $$v)
                      },
                      expression: "formData.nickName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "金额", prop: "productionCount" } },
                [
                  _c("a-input-number", {
                    attrs: { placeholder: "请输入金额" },
                    model: {
                      value: _vm.formData.money,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "money", $$v)
                      },
                      expression: "formData.money",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showScoreDetail
        ? _c(
            "div",
            [
              _c("g-title", [_vm._v("查看明细")]),
              _c("settlement-table", {
                attrs: {
                  month: _vm.formData.month,
                  settleId: _vm.formData.id,
                  taskIds: _vm.formData.taskIds
                    ? _vm.formData.taskIds.split(",")
                    : [],
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }