<template>
  <div :style="backCss">
    <div class="statistics-menu-list">
      <template v-for="(item, index) in copyColumns">
        <a-dropdown
          v-if="item.dataIndex != 'evalResult'"
          :trigger="['click']"
          :ref="'statistics-' + index"
          :key="index"
          :style="item.isNum ? '' : 'display: none;'"
          :placement="'topCenter'"
        >
          <div class="statistics">统计<a-icon type="caret-up" /></div>
          <a-menu slot="overlay">
            <a-menu-item v-if="item.isNum" @click="compute('meanNum', item.dataIndex, index, '求和')">
              求和
            </a-menu-item>
            <a-menu-item v-if="item.isNum" @click="compute('sumNum', item.dataIndex, index, '平均')">
              平均
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-dropdown
          v-else
          :trigger="['click']"
          :ref="'statistics-' + index"
          :key="index"
          :style="item.isNum ? '' : 'display: none;'"
          :placement="'topCenter'"
        >
          <div class="statistics">平均<a-icon type="caret-up" /></div>
          <a-menu slot="overlay">
            <a-menu-item v-if="item.isNum" @click="computeEval('sumNumEval', item.dataIndex, index, '硬伤')">
              硬伤
            </a-menu-item>
            <a-menu-item v-if="item.isNum" @click="computeEval('sumNumEval', item.dataIndex, index, '软伤')">
              软伤
            </a-menu-item>
            <a-menu-item v-if="item.isNum" @click="computeEval('sumNumEval', item.dataIndex, index, '错翻')">
              错翻
            </a-menu-item>
            <a-menu-item v-if="item.isNum" @click="computeEval('sumNumEval', item.dataIndex, index, '修改率')">
              修改率
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
    <a-spin :spinning="tableLoading">
      <vxe-table
        border="none"
        ref="xTree"
        :row-config="{ isCurrent: true, isHover: true }"
        :column-config="{ resizable: true }"
        @checkbox-change="selectChangeEvent"
        @checkbox-all="selectChangeEvent"
        @cell-click="cellClickEvent"
        @toggle-tree-expand="toggleExpandChangeEvent"
        @filter-visible="filterVisible"
        :footer-method="footerMethod"
        :tree-config="treeConfig"
        :filter-config="{
          remote: true,
          showIcon: false
          /*           iconNone: 'iconfont icon-shaixuan icon',
          iconMatch: 'iconfont icon-shaixuan icon filter-product-btn' */
        }"
        @filter-change="filterChangeEvent"
        :data="tableData"
      >
        <vxe-column
          v-for="record in columns"
          :key="record.dataIndex"
          :fixed="record.dataIndex == 'name' ? 'left' : record.dataIndex == 'totalScore' ? '' : ''"
          :field="record.dataIndex"
          :title="record.title"
          :minWidth="record.width"
          :tree-node="record.dataIndex == 'name'"
          show-overflow
          :filters="[]"
        >
          <template v-if="record.dataIndex == 'user'" v-slot="{ row }">
            <template v-if="row.chapterSortAndStageName">
              <a-select
                showArrow
                placeholder="选择用户"
                v-model="row.ownerId"
                option-filter-prop="label"
                @click.stop
                @change="listEidt(row)"
                style="margin: -5px 0;width:150px;"
                v-if="row.editable"
              >
                <a-select-option
                  :label="user.userName"
                  v-for="user in allUsers"
                  :key="user.userId"
                  :value="user.userId"
                >
                  <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="user.avatar" alt="" />
                  <span>{{ user.userName }}</span>
                </a-select-option>
              </a-select>
              <template v-else>
                <div style="display: flex;align-items: center;">
                  <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="row.avatar" alt="" />
                  {{ row.userName }}
                </div>
              </template>
            </template>
            <template v-else-if="row.platformAndCoordinator && row.productSortAndName">
              <span v-if="row.chapterOrderRange">
                <span>{{ row.chapterOrderRange }}</span
                ><span style="padding:0 3px 0 3px">&nbsp;</span> <span>{{ row.calcCount + getUnit(row.calcUnit) }}</span
                ><span style="padding:0 3px 0 3px">&nbsp;</span>
                <span v-if="row.unitPrice && row.moneyType">{{ row.unitPrice + moneyTypeMap[row.moneyType] }}</span>
                <span v-else-if="!row.unitPrice && row.moneyType"
                  ><span style="color:red">-</span> {{ moneyTypeMap[row.moneyType] }}</span
                >
                <span v-else-if="!row.unitPrice && !row.moneyType" style="color:red">-</span>
                <span>{{ '/' + getUnit(row.calcUnit) }}</span
                ><span style="padding:0 3px 0 3px">&nbsp;</span>
                <span>{{ row.resultPrice + moneyTypeMap[row.moneyType] }}</span>
              </span>
            </template>
            <template v-else-if="row.calcCounts && row.calcCounts.length">
              <!-- diyiceng -->
              <span>{{
                row.calcCounts
                  .map(item => {
                    return item.calcCount + getUnit(item.calcUnit)
                  })
                  .join('  ')
              }}</span>
              <span v-if="row.monies && row.monies.length"
                >&nbsp;{{
                  row.monies
                    .map(item => {
                      return item.money + moneyTypeMap[item.moneyType] || ''
                    })
                    .join('  ')
                }}</span
              >
            </template>
          </template>
          <template v-else-if="record.dataIndex == 'name'" v-slot="{ row }">
            <span
              >{{ row.name
              }}{{
                row.platformAndCoordinator && row.productSortAndName && row.hasChild && row.productTotal
                  ? '（' + row.productTotal + '）'
                  : ''
              }}
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'completeStatus'" v-slot="{ row }">
            <div v-if="row.chapterSortAndStageName" @click.stop style="display:flex">
              <actions-tasks
                :propsParams="row"
                :isMyTask="true"
                :isMyTaskList="true"
                :isCalendar="true"
                :loadData="getTableData"
              ></actions-tasks>
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'calcUnits'" v-slot="{ row }">
            <div v-if="row.chapterSortAndStageName">
              {{ getCalcUnits(row.calcUnits) }}
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'gmtSettled'" v-slot="{ row }">
            <span>
              {{
                row.settleStatus == 'NOT_SETTLE'
                  ? ''
                  : row.settleStatus == 'SYSTEM_SETTLED'
                  ? '系统已结算'
                  : row.settleStatus == 'USER_SETTLED'
                  ? '手动已结算'
                  : ''
              }}
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'totalScore'" v-slot="{ row }">
            <div
              v-if="row.platformAndCoordinator && row.productSortAndName && row.productTotal > 50"
              style="width:200px"
            >
              <a-pagination
                size="small"
                :showLessItems="true"
                :defaultPageSize="50"
                :default-current="1"
                :total="row.productTotal - 0"
                @change="
                  (page, pageSize) => {
                    treeTablePageChange(page, pageSize, row)
                  }
                "
              />
            </div>
            <div v-else-if="row.chapterSortAndStageName">
              {{ row.totalScore || '' }}
            </div>
          </template>
          <template v-else-if="record.dataIndex == 'evalResult'" v-slot="{ row }">
            <template v-if="row.chapterSortAndStageName">
              <span>
                <template v-for="(item, kpiViewindex) in row.evalResult.kpiValueErrors">
                  <a-tag v-if="item.count > 0" :key="kpiViewindex">
                    {{
                      (item.type == 'SOFT'
                        ? '软伤数'
                        : item.type == 'HARD'
                        ? '硬伤数'
                        : item.type == 'ERROR'
                        ? '错翻数'
                        : item.type == 'SUPER_HARD'
                        ? '超级硬伤数'
                        : item.type == 'V1_HARD'
                        ? '1改硬伤数'
                        : item.type == 'V2_HARD'
                        ? '2改硬伤数'
                        : item.type == 'MORE_THAN_V2_HARD'
                        ? '2改以上硬伤数'
                        : item.type == 'SUPER_SOFT'
                        ? '超级软伤'
                        : '') +
                        ' : ' +
                        item.count
                    }}
                  </a-tag>
                </template>
                <a-tag v-if="row.evalResult.hasKpiModifies && row.evalResult.kpiModifyRatio > 0">
                  修改率 :
                  <span :style="row.evalResult.textAmount || !row.isLocal ? '' : 'color:red'">{{
                    !row.isLocal
                      ? row.evalResult.kpiModifyRatio + '%'
                      : row.evalResult.textAmount
                      ? row.evalResult.kpiModifyRatio + '%'
                      : 'X'
                  }}</span>
                </a-tag>
              </span>
            </template>
          </template>
          <template v-else-if="record.dataIndex == 'finishStatus'" v-slot="{ row }">
            <span>
              {{
                row.finishStatus == 'ADVANCE'
                  ? '提前'
                  : row.finishStatus == 'ON_TIME'
                  ? '按时'
                  : row.finishStatus == 'DELAY'
                  ? '拖稿'
                  : ''
              }}
            </span>
          </template>
          <template v-else-if="record.dataIndex == 'deadline'" v-slot="{ row }">
            <a-date-picker
              v-if="row.editable"
              format="YYYY-MM-DD HH:mm"
              valueFormat="YYYY-MM-DD HH:mm"
              :show-time="{ format: 'HH:mm' }"
              placeholder="请选择截止日期"
              v-model="row.deadline"
              @ok="listEidt(row)"
              style="margin: -5px 0;min-width: 145px;"
            />
            <template v-else>
              {{ row.deadline }}
            </template>
          </template>
          <template v-else-if="record.dataIndex == 'money'" v-slot="{ row }">
            <a-input-number v-if="row.edit && !row.hasChild" v-model="row.money" @blur="saveMoney(row)" />
            <template v-else-if="!row.edit && !row.hasChild">
              <span @click.stop="moneyClick(row)">{{ row.money }}</span
              ><span>{{ row.moneyType && moneyTypeMap[row.moneyType] }}</span>
            </template>
          </template>
          <template v-else-if="record.dataIndex == 'stageName'" #filter="{ $panel, column }">
            <a-spin
              :spinning="filterLoading"
              style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
            >
              <a-tree
                v-model="checkedStageNameKeys"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info)
                  }
                "
                checkable
                :tree-data="treeData"
              />
            </a-spin>
          </template>
          <template v-else-if="record.dataIndex == 'settlementDate'" #filter="{ $panel, column }">
            <a-spin
              :spinning="filterLoading"
              style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
            >
              <a-tree
                v-model="checkedSettlementDateKeys"
                @expand="treeExpand"
                :defaultExpandedKeys="defaultOpenKeys"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info)
                  }
                "
                checkable
                :tree-data="treeData"
              />
            </a-spin>
          </template>
          <template v-if="record.dataIndex == 'name'" #filter="{ $panel, column }">
            <div style="padding:10px">
              <a-input
                placeholder="请输入作品名称"
                @pressEnter="inputFilter(record.dataIndex)"
                style="width: 150px;height: 24px;line-height: 24px;"
                v-model="productName"
              ></a-input>
            </div>
          </template>
          <!--           <template v-if="record.dataIndex == 'deadline'" #filter="{ $panel, column }">
            <a-spin
              :spinning="filterLoading"
              style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
            >
              <a-tree
                v-model="checkedDeadlineKeys"
                :defaultExpandedKeys="defaultOpenKeys"
                @expand="treeExpand"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info)
                  }
                "
                checkable
                :tree-data="treeData"
              />
            </a-spin>
          </template>
          <template v-else-if="record.dataIndex == 'completeStatus'" #filter="{ $panel, column }">
            <a-spin
              :spinning="filterLoading"
              style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
            >
              <a-tree
                v-model="checkedStatusKeys"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info)
                  }
                "
                checkable
                :tree-data="treeData"
              />
            </a-spin>
          </template>
          <template v-else-if="record.dataIndex == 'finishStatus'" #filter="{ $panel, column }">
            <a-spin
              :spinning="filterLoading"
              style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
            >
              <a-tree
                v-model="checkedFinishStatusKeys"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info)
                  }
                "
                checkable
                :tree-data="treeData"
              />
            </a-spin>
          </template>
          <template v-else-if="record.dataIndex == 'totalScore'" #filter="{ $panel, column }">
            <span>
              <a-input-number
                v-model="minTotalScore"
                :min="0"
                style=" width: 80px; text-align: center"
                placeholder="起始值"/>
              ~
              <a-input-number
                v-model="maxTotalScore"
                :min="0"
                style="width: 80px; text-align: center;"
                placeholder="结束值"
            /></span>
          </template>
          <template v-else-if="record.dataIndex == 'evalResult'" #filter="{ $panel, column }">
            <a-spin
              :spinning="filterLoading"
              style="padding-left:10px;padding-right:50px;min-height:30px;max-height:200px;overflow-y:auto"
            >
              <a-tree
                v-model="checkedEvalResultKeys"
                @select="
                  (selectedKeys, info) => {
                    treeSelect(record.dataIndex, selectedKeys, info)
                  }
                "
                checkable
                :tree-data="treeData"
              />
            </a-spin>
          </template> -->
          <!--         <template v-if="record.dataIndex == 'user'" #filter="{ $panel, column }">
          <a-input v-model="userName">
            <a-icon slot="prefix" type="search" />
          </a-input>
        </template> -->
        </vxe-column>
        <!--       <vxe-column fixed="right" minWidth="220">
        <template v-slot="{ row }">
          <div v-if="row.productSortAndName && row.productTotal > 50" style="width:200px">
            <a-pagination
              size="small"
              :showLessItems="true"
              :defaultPageSize="50"
              :default-current="1"
              :total="row.productTotal - 0"
              @change="
                (page, pageSize) => {
                  treeTablePageChange(page, pageSize, row)
                }
              "
            />
          </div>
        </template>
      </vxe-column> -->
      </vxe-table>
    </a-spin>
    <a-modal title="请更换人员" width="350px" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <a-select
        showArrow
        placeholder="选择用户"
        v-model="ownerId"
        option-filter-prop="label"
        @click.stop
        style="width:250px;"
      >
        <a-select-option :label="user.userName" v-for="user in allUsers" :key="user.userId" :value="user.userId">
          <img
            style="width:25px;height:25px;border-radius:50%;margin-right:10px;position: relative;top: -2px;"
            :src="user.avatar"
            alt=""
          />
          <span>{{ user.userName }}</span>
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import { checkPermission } from '@/utils/hasPermission'
import { createPriceUnitTypeList } from '@/enums/unitTypeList'
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasksBtn'
import moment from 'moment'
export default {
  components: {
    ActionsTasks
  },
  props: {
    userIds: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    allUsers: {
      type: Array,
      default: () => []
    },
    taskIds: {
      type: Array,
      default: () => []
    },
    month: {
      type: String,
      default: ''
    },
    settleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ownerId: undefined,
      visible: false,
      moneyTypeMap: {
        null: '',
        CNY: '元',
        TWD: '台币',
        JPY: '円',
        USD: '美元'
      },
      propUserIds: [],
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          width: 240,
          sorter: false,
          isNum: false,
          total: '统计'
        },
        /*         {
          title: '阶段',
          dataIndex: 'stageName',
          width: 120,
          sorter: false,
          isNum: false,
          total: '统计'
        }, */
        {
          title: '人员',
          dataIndex: 'user',
          width: 180,
          sorter: false,
          isNum: false,
          total: '统计'
        },
        {
          title: '截止日期',
          dataIndex: 'deadline',
          width: 170,
          sorter: false,
          isNum: false,
          total: '统计'
        },
        {
          title: '结算月份',
          dataIndex: 'settlementDate',
          width: 120,
          sorter: false,
          scopedSlots: { customRender: 'settlementDate' },
          isNum: false,
          total: '统计'
        },
        {
          title: '结算数量',
          dataIndex: 'calcUnits',
          width: 120,
          sorter: false,
          isNum: true,
          total: '统计'
        },
        {
          title: '系数',
          dataIndex: 'factor',
          width: 120,
          sorter: false,
          isNum: true,
          total: '统计'
        },
        {
          title: '考核得分',
          dataIndex: 'totalScore',
          width: 220,
          sorter: false,
          scopedSlots: { customRender: 'totalScore' },
          isNum: true,
          total: '统计'
        },
        {
          title: '金额',
          dataIndex: 'money',
          width: 120,
          sorter: false,
          isNum: true,
          total: '统计'
        },
        {
          title: '状态',
          dataIndex: 'gmtSettled',
          width: 120,
          sorter: false,
          isNum: false,
          total: '统计'
        }
      ],
      treeConfig: {
        lazy: true,
        hasChild: 'hasChild',
        rowField: 'id',
        loadMethod: this.loadChildrenMethod
      },
      checkedSettlementDateKeys: [],
      checkedDeadlineKeys: [],
      checkedStageNameKeys: [],
      defaultOpenKeys: [],
      checkedStatusKeys: [],
      checkedFinishStatusKeys: [],
      checkedEvalResultKeys: [],
      userName: '',
      filterLoading: false,
      treeData: [],
      copyColumns: [
        {
          title: '名称',
          dataIndex: 'name',
          width: 240,
          sorter: false,
          isNum: false,
          total: '统计'
        },
        /*         {
          title: '阶段',
          dataIndex: 'stageName',
          width: 120,
          sorter: false,
          isNum: false,
          total: '统计'
        }, */
        {
          title: '人员',
          dataIndex: 'user',
          width: 180,
          sorter: false,
          isNum: false,
          total: '统计'
        },
        {
          title: '截止日期',
          dataIndex: 'deadline',
          width: 170,
          sorter: false,
          isNum: false,
          total: '统计'
        },
        {
          title: '结算月份',
          dataIndex: 'settlementDate',
          width: 120,
          sorter: false,
          scopedSlots: { customRender: 'settlementDate' },
          isNum: false,
          total: '统计'
        },
        {
          title: '结算数量',
          dataIndex: 'calcUnits',
          width: 120,
          sorter: false,
          isNum: true,
          total: '统计'
        },
        {
          title: '系数',
          dataIndex: 'factor',
          width: 120,
          sorter: false,
          isNum: true,
          total: '统计'
        },
        {
          title: '考核得分',
          dataIndex: 'totalScore',
          width: 220,
          sorter: false,
          scopedSlots: { customRender: 'totalScore' },
          isNum: true,
          total: '统计'
        },
        {
          title: '金额',
          dataIndex: 'money',
          width: 120,
          sorter: false,
          isNum: true,
          total: '统计'
        },
        {
          title: '状态',
          dataIndex: 'gmtSettled',
          width: 120,
          sorter: false,
          isNum: false,
          total: '统计'
        }
      ],
      tableData: [],
      methodName: 'totalScore',
      taskList: [],
      propertys: [],
      selectRows: [],
      queryParmas: {
        current: 1,
        size: 50,
        groupBy: 'stageName',
        /* orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc', */
        orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc,deadline:asc'
      },
      color: '',
      isEdit: false,
      productName: '',
      filters: ['taskId', 'settlementDate'],
      gexpr: '0',
      isUserSelect: false,
      tableLoading: false,
      selectedTreeKeys: [],
      minTotalScore: null,
      maxTotalScore: null,
      nodeList: [],
      nodeListFixed: [],
      scrollList: [],
      moveNode: null,
      moveNodeFixed: null,
      unitPriceTypeList: []
    }
  },
  created() {
    this.getTableData()
    this.unitPriceTypeList = createPriceUnitTypeList()
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
    /*     this.$nextTick(() => {
      console.log(document.getElementsByClassName('vxe-table--fixed-wrapper'), 123)
      Array.from(document.getElementsByClassName('vxe-filter--btn')).forEach((item, index) => {
        item.className = item.className + ' filter-' + this.copyColumns[index].dataIndex + '-btn'
      })
    }) */
  },
  computed: {
    backCss() {
      let colorBack = this.color
      let color = this.$store.state.app.color
      let height = 'calc(100vh - 305px)'
      let stageColor = this.checkedStageNameKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let statusColor = this.checkedStatusKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let deadlineColor = this.checkedDeadlineKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let settlementDateColor = this.checkedSettlementDateKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let productNameColor = this.productName ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let finishColor = this.checkedFinishStatusKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let totalScoreColor = this.minTotalScore || this.maxTotalScore ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let evalResultColor = this.checkedEvalResultKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      return {
        '--back-color': colorBack,
        '--theme-color': color,
        '--table-height': height,
        '--stage-color': stageColor,
        '--deadline-color': deadlineColor,
        '--productName-color': productNameColor,
        '--settlementDate-color': settlementDateColor,
        '--finish-color': finishColor,
        '--status-color': statusColor,
        '--totalScore-color': totalScoreColor,
        '--evalResult-color': evalResultColor
      }
    }
  },
  watch: {
    userIds() {
      this.propUserIds = this.userIds
    },
    checkedDeadlineKeys(val) {
      console.log('onCheck', val)
    },
    nodeList(val) {
      /* if(!document.getElementsByClassName('vxe-table--fixed-wrapper')[0]) return */
      if (val.length) {
        if (
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document
                .getElementsByClassName('vxe-table--main-wrapper')[0]
                .getElementsByClassName('vxe-table--header')[0]
                .getElementsByTagName('thead')[0].childNodes[1]
            )
        }
        document
          .getElementsByClassName('vxe-table--main-wrapper')[0]
          .getElementsByClassName('vxe-table--header')[0]
          .getElementsByTagName('thead')[0]
          .appendChild(val[val.length - 1])
      } else {
        if (
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document
                .getElementsByClassName('vxe-table--main-wrapper')[0]
                .getElementsByClassName('vxe-table--header')[0]
                .getElementsByTagName('thead')[0].childNodes[1]
            )
        }
      }
    },
    nodeListFixed(val) {
      /* if(!document.getElementsByClassName('vxe-table--fixed-wrapper')[0]) return */
      if (val.length) {
        if (
          document
            .getElementsByClassName('vxe-table--fixed-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--fixed-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document
                .getElementsByClassName('vxe-table--fixed-wrapper')[0]
                .getElementsByClassName('vxe-table--header')[0]
                .getElementsByTagName('thead')[0].childNodes[1]
            )
        }
        document
          .getElementsByClassName('vxe-table--fixed-wrapper')[0]
          .getElementsByClassName('vxe-table--header')[0]
          .getElementsByTagName('thead')[0]
          .appendChild(val[val.length - 1])
      } else {
        if (
          document
            .getElementsByClassName('vxe-table--fixed-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--fixed-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document
                .getElementsByClassName('vxe-table--fixed-wrapper')[0]
                .getElementsByClassName('vxe-table--header')[0]
                .getElementsByTagName('thead')[0].childNodes[1]
            )
        }
      }
    }
  },
  methods: {
    moment,
    getUnit(data) {
      let text = ''
      if (!data) {
        return text
      }
      Object.values(this.unitPriceTypeList).forEach(item => {
        if (item.value == data) {
          text = item.label
        }
      })
      return text
    },
    checkPermission() {
      return checkPermission('statistics:settlement:edit')
    },
    moneyClick(row) {
      if (!this.checkPermission()) return
      row.edit = true
    },
    async saveMoney(row) {
      const res = await postAction('/kt/settle/updateTaskSettles', [{ taskId: row.taskId, resultPrice: row.money }])
      if (res.code == 200) {
        this.$message.success('保存成功')
        row.edit = false
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    getCalcUnits(calcUnits) {
      let text = []
      calcUnits?.forEach(item => {
        if (item.value) {
          let unit = ''
          switch (item.type) {
            case 'EACH':
              unit = item.value + '个'
              break
            case 'CHAPTER':
              unit = item.value + '话'
              break
            case 'VOLUME':
              unit = item.value + '部'
              break
            case 'PAGE':
              unit = item.value + '页'
              break
            case 'WORD':
              unit = item.value + '字'
              break
            case 'THOUSAND':
              unit = item.value + '千字'
              break
            case 'GRID':
              unit = item.value + '格'
              break
            case 'PERCENT':
              unit = item.value + '%'
              break
          }
          text.push(unit)
        }
      })
      text = text.join('')
      return text
    },
    copyNodeClick(e) {
      let node = document
        .getElementsByClassName('vxe-table--header-wrapper body--wrapper')[0]
        .getElementsByClassName('vxe-body--row')[0]
      let openArr = this.$refs.xTree.getTreeExpandRecords()
      if (
        openArr.findIndex(i => {
          return i._X_ROW_KEY == node.attributes[0].value
        }) > -1
      ) {
        console.log(this.tableData)
        this.tableData.forEach(item => {
          item.children.forEach(i => {
            if (i._X_ROW_KEY == node.attributes[0].value) {
              this.$refs.xTree.clearTreeExpandLoaded(
                openArr[
                  openArr.findIndex(i => {
                    return i._X_ROW_KEY == node.attributes[0].value
                  })
                ]
              )
              this.$refs.xTree.updateData()
            }
          })
        })
      }
    },
    handleScroll(e) {
      let openArr = this.$refs.xTree.getTreeExpandRecords()
      openArr = openArr.filter(item => item.platformAndCoordinator && item.productSortAndName)
      let copyNode = null
      Array.from(
        document.getElementsByClassName('vxe-table--main-wrapper')[0].getElementsByClassName('vxe-body--row')
      ).forEach(item => {
        if (
          openArr.findIndex(i => {
            return i._X_ROW_KEY == item.attributes[0].value
          }) > -1
        ) {
          if (item.getClientRects()[0].top < 160) {
            copyNode = item.cloneNode(true)
            if (copyNode.getElementsByClassName('ant-pagination mini')[0]) {
              this.moveNode = null
              let parent = copyNode.getElementsByClassName('ant-pagination mini')[0].parentNode
              parent.removeChild(copyNode.getElementsByClassName('ant-pagination mini')[0])
              parent.appendChild(item.getElementsByClassName('ant-pagination mini')[0])
              this.moveNode = item
            }
            copyNode.style.backgroundColor = '#fff'
            /*             copyNode.getElementsByClassName('vxe-icon--caret-right')[0].className =
              'vxe-tree--node-btn vxe-icon--caret-right rotate90' */
            /* copyNode.document.getElementsByClassName('col--last')[0].childNodes[0].childNodes[0].appendChild(item.getElementsByClassName('ant-pagination mini')[0]) */
            copyNode.addEventListener('click', this.copyNodeClick)
            if (
              this.nodeList.findIndex(n => {
                return n.attributes[0].value == copyNode.attributes[0].value
              }) == -1
            ) {
              this.scrollList.push(e.target.scrollTop)
              this.nodeList.push(copyNode)
            }
          }
        }
      })
      if (e.target.scrollTop < this.scrollList[this.scrollList.length - 1]) {
        this.scrollList.splice(this.scrollList.length - 1, 1)
        if (this.nodeList[this.nodeList.length - 1].getElementsByClassName('ant-pagination mini')[0]) {
          let pageNode = this.nodeList[this.nodeList.length - 1].getElementsByClassName('ant-pagination mini')[0]
          this.moveNode.childNodes[this.moveNode.childNodes.length - 1].childNodes[0].childNodes[0].appendChild(
            pageNode
          )
        }
        this.nodeList.splice(this.nodeList.length - 1, 1)
      }
      let copyNodeFixed = null
      Array.from(
        document.getElementsByClassName('vxe-table--main-wrapper')[0].getElementsByClassName('vxe-body--row')
      ).forEach(item => {
        if (
          openArr.findIndex(i => {
            return i._X_ROW_KEY == item.attributes[0].value
          }) > -1
        ) {
          if (item.getClientRects()[0].top < 160) {
            copyNodeFixed = item.cloneNode(true)
            if (copyNodeFixed.getElementsByClassName('ant-pagination mini')[0]) {
              this.moveNodeFixed = null
              this.moveNodeFixed = item
            }
            copyNodeFixed.style.backgroundColor = '#fff'
            copyNodeFixed.style.position = 'relative'
            copyNodeFixed.style.zIndex = '10000'
            copyNodeFixed.getElementsByClassName('vxe-icon--caret-right')[0].className =
              'vxe-tree--node-btn vxe-icon--caret-right rotate90'
            /* copyNode.document.getElementsByClassName('col--last')[0].childNodes[0].childNodes[0].appendChild(item.getElementsByClassName('ant-pagination mini')[0]) */
            copyNodeFixed.addEventListener('click', this.copyNodeClick)
            if (
              this.nodeListFixed.findIndex(n => {
                return n.attributes[0].value == copyNodeFixed.attributes[0].value
              }) == -1
            ) {
              this.scrollList.push(e.target.scrollTop)
              this.nodeListFixed.push(copyNodeFixed)
            }
          }
        }
      })
      if (e.target.scrollTop < this.scrollList[this.scrollList.length - 1]) {
        this.scrollList.splice(this.scrollList.length - 1, 1)
        this.nodeListFixed.splice(this.nodeListFixed.length - 1, 1)
      }
    },
    treeExpand(keys) {
      this.defaultOpenKeys = keys
    },
    async handleOk() {
      this.visible = false
      let saveArr = this.selectRows
        .filter(item => item.chapterSortAndStageName)
        .map(item => {
          return {
            taskId: item.taskId,
            ownerId: item.ownerId,
            id: item.taskId,
            chapterId: item.chapterId,
            stageId: item.stageId,
            productionId: item.productionId,
            settlementDate: item.settlementDate ? item.settlementDate + '-01' : null
          }
        })
      saveArr.forEach(item => {
        item.ownerId = this.ownerId
      })
      const res = await postAction('/tasks/updateTasks', saveArr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.ownerId = undefined
        this.getTableData()
        this.clearSelectKeys()
      } else {
        this.$message.error(res.msg)
      }
    },
    async listEidt(row) {
      let saveArr = [
        {
          taskId: row.taskId,
          ownerId: row.ownerId,
          id: row.taskId,
          chapterId: row.chapterId,
          stageId: row.stageId,
          productionId: row.productionId,
          settlementDate: row.settlementDate ? row.settlementDate + '-01' : null,
          deadline:
            row.deadline.length <= 17 && row.deadline.length > 10
              ? row.deadline + ':00'
              : row.deadline.length <= 10
              ? row.deadline + ' 23:59:59'
              : row.deadline
        }
      ]
      const res = await postAction('/tasks/updateTasks', saveArr)
      if (res.code == 200) {
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    handleCancel() {
      this.visible = false
      this.ownerId = undefined
    },
    treeSelect(type, selectedKeys, info) {
      console.log(selectedKeys, info)
      if (type == 'deadline') {
        this.checkedDeadlineKeys = selectedKeys
      } else if (type == 'settlementDate') {
        this.checkedSettlementDateKeys = selectedKeys
      } else if (type == 'stageName') {
        this.checkedStageNameKeys = selectedKeys
      } else if (type == 'completeStatus') {
        this.checkedStatusKeys = selectedKeys
      } else if (type == 'finishStatus') {
        this.checkedFinishStatusKeys = selectedKeys
      } else if (type == 'evalResult') {
        this.checkedEvalResultKeys = selectedKeys
      }
      this.selectedTreeKeys = selectedKeys
      this.$refs.xTree.closeFilter()
      if (this.filters.indexOf(type) == -1) {
        this.filters.push(type)
        this.gexpr = this.filters.length
        this.filters.forEach((i, idx) => {
          this.gexpr = this.gexpr + '&' + idx
        })
      }
      this.getTableData()
    },
    handleUserSelectOn() {
      this.visible = true
    },
    handleEditSelectOn(isEditSelect) {
      this.selectRows
        .filter(item => item.chapterSortAndStageName)
        .forEach(item => {
          item.editable = isEditSelect
        })
    },
    async filterVisible(e) {
      let res = null
      this.filterLoading = true
      if (e.visible) {
        switch (e.property) {
          case 'deadline':
            res = await postAction('/kt/task/beanSearcher', {
              current: 1,
              size: 50,
              groupBy: 'deadline'
            })
            res.data = res.data.filter(item => item.deadline)
            let dateArr = res.data.map(item => {
              return (
                item.deadline.split('-')[0] +
                '-' +
                item.deadline.split('-')[1] +
                '-' +
                item.deadline.split(' ')[0].split('-')[2]
              )
            })
            let dateTree = []
            dateArr.forEach(item => {
              if (
                dateTree.every(i => {
                  return i.value != item.split('-')[0]
                })
              ) {
                dateTree.push({
                  title: item.split('-')[0] + '年',
                  value: item.split('-')[0],
                  key: item.split('-')[0],
                  children: []
                })
              }
              dateTree.forEach(i => {
                dateArr.forEach(item => {
                  if (i.value == item.split('-')[0]) {
                    if (
                      i.children.every(d => {
                        return d.value.split('-')[1] != item.split('-')[1]
                      })
                    ) {
                      i.children.push({
                        title: item.split('-')[1][0] != '0' ? item.split('-')[1] + '月' : item.split('-')[1][1] + '月',
                        value: item.split('-')[0] + '-' + item.split('-')[1],
                        key: item.split('-')[0] + '-' + item.split('-')[1],
                        children: []
                      })
                    }
                  }
                })
              })
              dateTree.forEach(i => {
                dateArr.forEach(item => {
                  if (i.value == item.split('-')[0]) {
                    i.children?.forEach(c => {
                      if (c.value.split('-')[1] == item.split('-')[1]) {
                        if (
                          c.children.every(e => {
                            return e.value.split('-')[2] != item.split('-')[2]
                          })
                        ) {
                          c.children.push({
                            title:
                              item.split('-')[2][0] != '0' ? item.split('-')[2] + '日' : item.split('-')[2][1] + '日',
                            value: item,
                            key: item
                          })
                        }
                      }
                    })
                  }
                })
              })
            })
            this.filterLoading = false
            dateTree.forEach(item => {
              item.children = item.children?.sort((a, b) => {
                return a.title.split('月')[0] - b.title.split('月')[0]
              })
            })
            this.treeData = dateTree
            return
          case 'settlementDate':
            res = await postAction('/kt/task/beanSearcher', {
              current: 1,
              size: 50,
              groupBy: 'settlementDate'
            })
            res.data = res.data.filter(item => item.settlementDate)
            let dateArr2 = res.data.map(item => {
              return item.settlementDate.split('-')[0] + '-' + item.settlementDate.split('-')[1]
            })
            let dateTree2 = []
            dateArr2.forEach(item => {
              if (
                dateTree2.every(i => {
                  return i.value != item.split('-')[0]
                })
              ) {
                dateTree2.push({
                  title: item.split('-')[0] + '年',
                  value: item.split('-')[0],
                  key: item.split('-')[0],
                  children: []
                })
              }
              dateTree2.forEach(i => {
                dateArr2.forEach(item => {
                  if (i.value == item.split('-')[0]) {
                    if (
                      i.children.every(d => {
                        return d.value.split('-')[1] != item.split('-')[1]
                      })
                    ) {
                      i.children.push({
                        title: item.split('-')[1][0] != '0' ? item.split('-')[1] + '月' : item.split('-')[1][1] + '月',
                        value: item,
                        key: item
                      })
                    }
                  }
                })
              })
            })
            this.filterLoading = false
            dateTree2.forEach(item => {
              item.children = item.children?.sort((a, b) => {
                return a.title.split('月')[0] - b.title.split('月')[0]
              })
            })
            this.treeData = dateTree2
            return
          case 'stageName':
            let data = JSON.parse(JSON.stringify({ ...this.queryParmas }))
            for (let key in data) {
              if (!isNaN(Number(key.split('.')[0], 10)) && key.split('.')[1].split('-')[0] != 'ownerId') {
                delete data[key]
              }
              if (key.indexOf('group') > -1) {
                delete data[key]
              }
            }
            data.groupBy = 'stageName'
            res = await postAction('/kt/task/beanSearcher', data)
            res.data = res.data.filter(item => item.stageName)
            this.filterLoading = false
            this.treeData = []
            let dateTree3 = []
            res.data?.forEach(item => {
              dateTree3.push({
                title: item.stageName,
                value: item.stageName,
                key: item.stageName
              })
            })
            this.treeData = dateTree3
            return
          case 'completeStatus':
            let data2 = JSON.parse(JSON.stringify({ ...this.queryParmas }))
            for (let key in data2) {
              if (!isNaN(Number(key.split('.')[0], 10)) && key.split('.')[1].split('-')[0] != 'ownerId') {
                delete data2[key]
              }
              if (key.indexOf('group') > -1) {
                delete data2[key]
              }
            }
            data2.groupBy = 'taskStatus'
            res = await postAction('/kt/task/beanSearcher', data2)
            res.data = res.data?.filter(item => item.taskStatus)
            this.filterLoading = false
            this.treeData = []
            let dateTree4 = []
            res.data?.forEach(item => {
              dateTree4.push({
                title: this.getTaskStatus(item.taskStatus),
                value: item.taskStatus,
                key: item.taskStatus
              })
            })
            this.treeData = dateTree4
            return
          case 'finishStatus':
            this.filterLoading = false
            this.treeData = [
              {
                title: '提前',
                value: 'ADVANCE',
                key: 'ADVANCE'
              },
              {
                title: '按时',
                value: 'ON_TIME',
                key: 'ON_TIME'
              },
              {
                title: '拖稿',
                value: 'DELAY',
                key: 'DELAY'
              }
            ]
            return
          case 'evalResult':
            let data3 = JSON.parse(JSON.stringify({ ...this.queryParmas }))
            for (let key in data3) {
              if (!isNaN(Number(key.split('.')[0], 10)) && key.split('.')[1].split('-')[0] != 'ownerId') {
                delete data3[key]
              }
              if (key.indexOf('group') > -1) {
                delete data3[key]
              }
            }
            res = await getAction('/kt/task/kpis', data3)
            this.filterLoading = false
            this.treeData = res.data
            this.treeData.forEach(item => {
              item.children?.forEach((i, idx) => {
                if (i.value == '0' || i.key.split('-')[0] == 'MODIFY') {
                  item.children.splice(idx, 1)
                }
              })
            })
            this.treeData = this.treeData.filter(item => item.children.length)
            return
        }
      }
    },
    deadlineChange(date, dateString) {
      this.deadline = dateString
    },
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTree.toggleTreeExpand(row)
      this.$refs.xTree.updateData()
      return
    },
    compute(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText = menuText + '：' + this[methodName]([], index)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计' && i == menuIndex) {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    computeEval(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText =
                    menuText + '：' + this[methodName]([], index, menuText)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计') {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    sumNumEval(list, field, menuText) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      let type = ''
      if (menuText == '软伤') {
        type = 'SOFT'
      } else if (menuText == '硬伤') {
        type = 'HARD'
      } else if (menuText == '修改率') {
        type = 'kpiModifyRatio'
      } else if (menuText == '错翻') {
        type = 'ERROR'
      }
      console.log(list)
      let count = 0
      if (type == 'SOFT') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'SOFT') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'HARD') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'HARD') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'ERROR') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'ERROR') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'kpiModifyRatio') {
        list.forEach(item => {
          if (item.evalResult.kpiModifyRatio) {
            count = count - 0 + (item.evalResult.kpiModifyRatio - 0)
          }
        })
      }
      let num = count / list.length
      if (type == 'kpiModifyRatio') {
        return num.toFixed(2) + '%'
      }
      return num.toFixed(2) || 0
    },
    treeTablePageChange(page, pageSize, row) {
      row.page = page
      this.$refs.xTree.reloadTreeExpand(row)
    },
    meanNum(list, field) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      console.log(list, field)
      if (field == 'totalScore') {
        let count = 0
        list
          .filter(item => item.totalScore)
          .forEach(item => {
            count += Number(item.totalScore)
          })
        return count || 0
      } else if (field == 'calcUnits') {
        let text = []
        let count = [
          { type: 'EACH', value: 0 },
          { type: 'CHAPTER', value: 0 },
          { type: 'VOLUME', value: 0 },
          { type: 'PAGE', value: 0 },
          { type: 'WORD', value: 0 },
          { type: 'THOUSAND', value: 0 },
          { type: 'GRID', value: 0 },
          { type: 'PERCENT', value: 0 }
        ]
        list
          .filter(item => item.calcUnits)
          .forEach(item => {
            item.calcUnits.forEach(i => {
              count.forEach(c => {
                if (c.type == i.type) {
                  c.value += i.value - 0
                }
              })
            })
          })
        return this.getCalcUnits(count)
      } else if (field == 'factor') {
        let count = 0
        list
          .filter(item => item.factor)
          .forEach(item => {
            count += Number(item.factor)
          })
        return count.toFixed(2) || 0
      }
    },
    allNum(list, field) {
      return list.length
    },
    sumNum(list, field) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      if(!list.length) return 0
      if (field == 'totalScore') {
        let count = 0
        list
          .filter(item => item.totalScore)
          .forEach(item => {
            count += Number(item.totalScore)
          })
        let num = count / list.length
        return num.toFixed(2) || 0
      } else if (field == 'calcUnits') {
        let text = []
        let count = [
          { type: 'EACH', value: 0 },
          { type: 'CHAPTER', value: 0 },
          { type: 'VOLUME', value: 0 },
          { type: 'PAGE', value: 0 },
          { type: 'WORD', value: 0 },
          { type: 'THOUSAND', value: 0 },
          { type: 'GRID', value: 0 },
          { type: 'PERCENT', value: 0 }
        ]
        list
          .filter(item => item.calcUnits)
          .forEach(item => {
            item.calcUnits.forEach(i => {
              count.forEach(c => {
                if (c.type == i.type) {
                  c.value += i.value - 0
                }
              })
            })
          })
        count.forEach(item => {
          if (item.value) {
            item.value = item.value / list.length
            item.value = item.value.toFixed(2) || 0
          }
        })
        return this.getCalcUnits(count)
      } else if (field == 'factor') {
        let count = 0
        list
          .filter(item => item.factor)
          .forEach(item => {
            count += Number(item.factor)
          })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
    },
    cellClickEvent(e) {
      if (e._columnIndex == 6) return
      if (e.row.groupCount) {
        if (e.row.expend) {
          e.row.expend = false
        } else {
          e.row.expend = true
        }
        this.$refs.xTree.toggleTreeExpand(e.row)
        this.$refs.xTree.updateData()
        return
      }
      /*       if (e._columnIndex == 8) {
        e.row.edit = true
        this.$forceUpdate()
      } */
      /*       if (e._columnIndex && !this.isEdit) {
        const { productName, chapterOrder, stageName } = e.row
        this.$router.push({
          path: `/translate/mytasks/detail/${e.row.taskId}/${e.row.productionId}/${
            e.row.chapterId
          }?&record=${encodeURIComponent(
            JSON.stringify({ platformId: e.row.platformId, productionName: e.row.productName })
          )}&isMyTask=${true}&name=${productName + '-' + chapterOrder + '-' + stageName}`
        })
      } */
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (this.propertys.includes(column.property)) {
            return parseInt(this[this.methodName](data, column.property))
          }
          if (columnIndex == 0) {
            return '已选择：' + this.selectRows.filter(item => item.chapterSortAndStageName).length + '项'
          }
          return ''
        })
      ]
    },
    async getTableData() {
      this.tableLoading = true
      this.queryParmas = {
        current: 1,
        size: 50,
        groupBy: 'stageName',
        /* orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc' */
        orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc,deadline:asc'
      }
      if (this.gexpr || this.gexpr === 0) {
        this.queryParmas.gexpr = this.gexpr
        this.filters.forEach((item, index) => {
          if (item == 'deadline' || item == 'settlementDate') {
            /*             this.checkedDeadlineKeys.forEach((i, idx) => {
              if (item == 'deadline') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            }) */
            /*             this.checkedSettlementDateKeys?.forEach((i, idx) => {
              if (item == 'settlementDate') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            }) */
            this.queryParmas['1.settlementDate-0'] = this.month + '%'
            this.queryParmas[index + '.' + item + '-op'] = 'ol'
          } else if (item == 'user') {
            this.queryParmas[index + '.userName'] = this.userName
            this.queryParmas[index + '.userName' + '-op'] = 'ct'
          } else if (item == 'stageName') {
            this.checkedStageNameKeys?.forEach((i, idx) => {
              if (item == 'stageName') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.stageName' + '-op'] = 'eq'
          } else if (item == 'taskId') {
            this.taskIds?.forEach((i, idx) => {
              if (item == 'taskId') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskId' + '-op'] = 'il'
          } else if (item == 'name') {
            this.queryParmas[index + '.productName-0'] = this.productName
            this.queryParmas[index + '.productName-op'] = 'ct'
          } else if (item == 'completeStatus') {
            this.checkedStatusKeys?.forEach((i, idx) => {
              if (item == 'completeStatus') {
                this.queryParmas[index + '.taskStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskStatus' + '-op'] = 'ct'
          } else if (item == 'finishStatus') {
            this.checkedFinishStatusKeys?.forEach((i, idx) => {
              if (item == 'finishStatus') {
                this.queryParmas[index + '.finishStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.finishStatus' + '-op'] = 'ct'
          } else if (item == 'totalScore') {
            if (this.maxTotalScore && this.minTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore-1'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'bt'
            } else if (!this.minTotalScore && this.minTotalScore !== 0 && this.maxTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'le'
            } else if (this.minTotalScore && !this.maxTotalScore && this.maxTotalScore !== 0) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'ge'
            }
          } else if (item == 'evalResult') {
            let arr = []
            this.treeData.forEach(a => {
              a.children?.forEach(i => {
                this.checkedEvalResultKeys.forEach(k => {
                  if (i.key == k) {
                    arr.push(i)
                  }
                })
              })
            })
            arr.forEach((i, idx) => {
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-' + idx] = i.value
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-op'] = 'eq'
            })
          }
        })
      }
      this.propUserIds?.forEach((item, index) => {
        this.queryParmas[this.filters.length + '.ownerId' + '-' + index] = item
        this.queryParmas[this.filters.length + '.ownerId' + '-op'] = 'il'
      })
      this.queryParmas.settleId = this.settleId
      const res = await postAction('/kt/task/beanSearcher', this.queryParmas)
      try {
        if (res.code == 200) {
          res.data?.forEach((item, index) => {
            item.stageName = item.stageName
            item.name = item.stageName
            item.hasChild = item.groupCount ? true : false
            item.productTotal = item.groupCount
            item.id = index
          })
          this.tableData = res.data
          /*           if (
            this.columns.some(item => {
              return item.isNum
            })
          ) {
            Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
              .filter((item, index) => {
                return index != 0
              })
              .forEach((item, index) => {
                if (item.childNodes[0]) {
                  item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + index][0].$el)
                }
              })
          } */
          this.$nextTick(() => {
            document
              .getElementsByClassName('vxe-table--body-wrapper body--wrapper')[0]
              .addEventListener('scroll', this.handleScroll)
          })
          this.tableLoading = false
          /* setTimeout(() => {
            if (this.userIds.length && this.filters.length>3) {
              this.$refs.xTree.setAllTreeExpand(true)
            }
          }, 0) */
        }
      } catch (err) {}
    },
    getEvalResultText(type) {
      let text = ''
      switch (type) {
        case 'ERROR':
          text = 'errorAmount'
          break
        case 'SOFT':
          text = 'softAmount'
          break
        case 'HARD':
          text = 'hardAmount'
          break
        case 'SUPER_HARD':
          text = 'superHardAmount'
          break
        case 'V1_HARD':
          text = 'firstModHardAmount'
          break
        case 'V2_HARD':
          text = 'secondModHardAmount'
          break
        case 'MORE_THAN_V2_HARD':
          text = 'multipleHardAmount'
          break
        case 'SUPER_SOFT':
          text = 'superSoftAmount'
          break
        case 'MODIFY':
          text = 'kpiModifyRatio'
          break
      }
      return text
    },
    inputFilter(dataIndex) {
      this.filterChangeEvent({
        column: {
          property: dataIndex
        },
        $event: {
          target: {
            innerText: '筛选'
          }
        }
      })
      this.$refs.xTree.closeFilter()
    },
    async loadChildrenMethod({ row }) {
      this.queryParmas.settleId = this.settleId
      // 异步加载子节点
      if (row.stageName && !row.platformAndCoordinator) {
        this.queryParmas = {
          current: 1,
          size: 50,
          groupBy: 'stageName',
          /* orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc' */
          orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc,deadline:asc'
        }
        this.queryParmas.groupBy = 'platformAndCoordinator'
        this.queryParmas.gexpr = 'group0&' + this.gexpr
        this.queryParmas['group0.stageName'] = row.stageName
        this.filters.forEach((item, index) => {
          if (item == 'deadline' || item == 'settlementDate') {
            /* this.checkedDeadlineKeys.forEach((i, idx) => {
              if (item == 'deadline') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            })
            this.checkedSettlementDateKeys?.forEach((i, idx) => {
              if (item == 'settlementDate') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            }) */
            this.queryParmas['1.settlementDate-0'] = this.month + '%'
            this.queryParmas[index + '.' + item + '-op'] = 'ol'
          } else if (item == 'user') {
            this.queryParmas[index + '.userName'] = this.userName
            this.queryParmas[index + '.userName' + '-op'] = 'ct'
          } else if (item == 'stageName') {
            this.checkedStageNameKeys?.forEach((i, idx) => {
              if (item == 'stageName') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.stageName' + '-op'] = 'eq'
          } else if (item == 'taskId') {
            this.taskIds?.forEach((i, idx) => {
              if (item == 'taskId') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskId' + '-op'] = 'il'
          } else if (item == 'name') {
            this.queryParmas[index + '.productName-0'] = this.productName
            this.queryParmas[index + '.productName-op'] = 'ct'
          } else if (item == 'completeStatus') {
            this.checkedStatusKeys?.forEach((i, idx) => {
              if (item == 'completeStatus') {
                this.queryParmas[index + '.taskStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskStatus' + '-op'] = 'ct'
          } else if (item == 'finishStatus') {
            this.checkedFinishStatusKeys?.forEach((i, idx) => {
              if (item == 'finishStatus') {
                this.queryParmas[index + '.finishStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.finishStatus' + '-op'] = 'ct'
          } else if (item == 'totalScore') {
            if (this.maxTotalScore && this.minTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore-1'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'bt'
            } else if (!this.minTotalScore && this.minTotalScore !== 0 && this.maxTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'le'
            } else if (this.minTotalScore && !this.maxTotalScore && this.maxTotalScore !== 0) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'ge'
            }
          } else if (item == 'evalResult') {
            let arr = []
            this.treeData.forEach(a => {
              a.children?.forEach(i => {
                this.checkedEvalResultKeys.forEach(k => {
                  if (i.key == k) {
                    arr.push(i)
                  }
                })
              })
            })
            arr.forEach((i, idx) => {
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-' + idx] = i.value
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-op'] = 'eq'
            })
          }
        })
        this.propUserIds?.forEach((item, index) => {
          this.queryParmas[this.filters.length + '.ownerId' + '-' + index] = item
          this.queryParmas[this.filters.length + '.ownerId' + '-op'] = 'il'
        })
        this.queryParmas.settleId = this.settleId
        const res = await postAction('/kt/task/beanSearcher', this.queryParmas)
        try {
          if (res.code == 200) {
            res.data?.forEach(item => {
              item.stageName = row.stageName
              item.platformAndCoordinator = item.platformAndCoordinator + '-' + item.coordinatorName
              item.name = item.platformAndCoordinator
              item.hasChild = item.groupCount ? true : false
              item.productTotal = item.groupCount
              item.id = item.platformAndCoordinator
            })
            this.clearSelectKeys()
          }
          /* setTimeout(() => {
            if (this.userIds.length && this.filters.length>3) {
              this.$refs.xTree.setAllTreeExpand(true)
            }
          }, 0) */
          return res.data
        } catch (err) {}
      } else if (row.platformAndCoordinator && !row.productSortAndName) {
        this.queryParmas = {
          current: 1,
          size: 50,
          groupBy: 'stageName',
          /* orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc' */
          orderBy: 'platformOrder:asc,productOrder:asc,chapterOrder:asc,stageOrder:asc,deadline:asc'
        }
        this.queryParmas.groupBy = 'productSortAndName'
        this.queryParmas.gexpr = 'group0&group1&' + this.gexpr
        this.queryParmas['group0.stageName'] = row.stageName
        this.queryParmas['group1.platformAndCoordinator'] = row.platformAndCoordinator.split('-')[0]
        this.filters.forEach((item, index) => {
          if (item == 'deadline' || item == 'settlementDate') {
            /* this.checkedDeadlineKeys.forEach((i, idx) => {
              if (item == 'deadline') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            })
            this.checkedSettlementDateKeys?.forEach((i, idx) => {
              if (item == 'settlementDate') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            }) */
            this.queryParmas['1.settlementDate-0'] = this.month + '%'
            this.queryParmas[index + '.' + item + '-op'] = 'ol'
          } else if (item == 'user') {
            this.queryParmas[index + '.userName'] = this.userName
            this.queryParmas[index + '.userName' + '-op'] = 'ct'
          } else if (item == 'stageName') {
            this.checkedStageNameKeys?.forEach((i, idx) => {
              if (item == 'stageName') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.stageName' + '-op'] = 'eq'
          } else if (item == 'taskId') {
            this.taskIds?.forEach((i, idx) => {
              if (item == 'taskId') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskId' + '-op'] = 'il'
          } else if (item == 'name') {
            this.queryParmas[index + '.productName-0'] = this.productName
            this.queryParmas[index + '.productName-op'] = 'ct'
          } else if (item == 'completeStatus') {
            this.checkedStatusKeys?.forEach((i, idx) => {
              if (item == 'completeStatus') {
                this.queryParmas[index + '.taskStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskStatus' + '-op'] = 'ct'
          } else if (item == 'finishStatus') {
            this.checkedFinishStatusKeys?.forEach((i, idx) => {
              if (item == 'finishStatus') {
                this.queryParmas[index + '.finishStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.finishStatus' + '-op'] = 'ct'
          } else if (item == 'totalScore') {
            if (this.maxTotalScore && this.minTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore-1'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'bt'
            } else if (!this.minTotalScore && this.minTotalScore !== 0 && this.maxTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'le'
            } else if (this.minTotalScore && !this.maxTotalScore && this.maxTotalScore !== 0) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'ge'
            }
          } else if (item == 'evalResult') {
            let arr = []
            this.treeData.forEach(a => {
              a.children?.forEach(i => {
                this.checkedEvalResultKeys.forEach(k => {
                  if (i.key == k) {
                    arr.push(i)
                  }
                })
              })
            })
            arr.forEach((i, idx) => {
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-' + idx] = i.value
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-op'] = 'eq'
            })
          }
        })
        this.propUserIds?.forEach((item, index) => {
          this.queryParmas[this.filters.length + '.ownerId' + '-' + index] = item
          this.queryParmas[this.filters.length + '.ownerId' + '-op'] = 'il'
        })
        this.queryParmas.settleId = this.settleId
        const res = await postAction('/kt/task/beanSearcher', this.queryParmas)
        try {
          if (res.code == 200) {
            res.data?.forEach(item => {
              item.stageName = row.stageName
              item.platformAndCoordinator = row.platformAndCoordinator.split('-')[0]
              item.name = item.productSortAndName
              item.productTotal = item.groupCount
              item.page = 1
              item.hasChild = item.groupCount ? true : false
              item.id = item.productSortAndName
            })
            this.clearSelectKeys()
          }
          /* setTimeout(() => {
            if (this.userIds.length && this.filters.length>3) {
              this.$refs.xTree.setAllTreeExpand(true)
            }
          }, 0) */
          return res.data
        } catch (err) {}
      } else if (row.platformAndCoordinator && row.productSortAndName) {
        if (this.queryParmas.groupBy) delete this.queryParmas.groupBy
        this.queryParmas.gexpr = 'group0&group1&group2&' + this.gexpr
        this.queryParmas['group0.stageName'] = row.stageName
        this.queryParmas['group1.platformAndCoordinator'] = row.platformAndCoordinator.split('-')[0]
        this.queryParmas['group2.productSortAndName'] = row.productSortAndName
        this.filters.forEach((item, index) => {
          if (item == 'deadline' || item == 'settlementDate') {
            /* this.checkedDeadlineKeys.forEach((i, idx) => {
              if (item == 'deadline') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            })
            this.checkedSettlementDateKeys?.forEach((i, idx) => {
              if (item == 'settlementDate') {
                this.queryParmas[index + '.' + item + '-' + idx] = i + '%'
              }
            }) */
            this.queryParmas['1.settlementDate-0'] = this.month + '%'
            this.queryParmas[index + '.' + item + '-op'] = 'ol'
          } else if (item == 'user') {
            this.queryParmas[index + '.userName'] = this.userName
            this.queryParmas[index + '.userName' + '-op'] = 'ct'
          } else if (item == 'stageName') {
            this.checkedStageNameKeys?.forEach((i, idx) => {
              if (item == 'stageName') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.stageName' + '-op'] = 'eq'
          } else if (item == 'taskId') {
            this.taskIds?.forEach((i, idx) => {
              if (item == 'taskId') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskId' + '-op'] = 'il'
          } else if (item == 'name') {
            this.queryParmas[index + '.productName-0'] = this.productName
            this.queryParmas[index + '.productName-op'] = 'ct'
          } else if (item == 'completeStatus') {
            this.checkedStatusKeys?.forEach((i, idx) => {
              if (item == 'completeStatus') {
                this.queryParmas[index + '.taskStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.taskStatus' + '-op'] = 'ct'
          } else if (item == 'finishStatus') {
            this.checkedFinishStatusKeys?.forEach((i, idx) => {
              if (item == 'finishStatus') {
                this.queryParmas[index + '.finishStatus-' + idx] = i
              }
            })
            this.queryParmas[index + '.finishStatus' + '-op'] = 'ct'
          } else if (item == 'totalScore') {
            if (this.maxTotalScore && this.minTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore-1'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'bt'
            } else if (!this.minTotalScore && this.minTotalScore !== 0 && this.maxTotalScore) {
              this.queryParmas[index + '.totalScore-0'] = this.maxTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'le'
            } else if (this.minTotalScore && !this.maxTotalScore && this.maxTotalScore !== 0) {
              this.queryParmas[index + '.totalScore-0'] = this.minTotalScore
              this.queryParmas[index + '.totalScore' + '-op'] = 'ge'
            }
          } else if (item == 'evalResult') {
            let arr = []
            this.treeData.forEach(a => {
              a.children?.forEach(i => {
                this.checkedEvalResultKeys.forEach(k => {
                  if (i.key == k) {
                    arr.push(i)
                  }
                })
              })
            })
            arr.forEach((i, idx) => {
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-' + idx] = i.value
              this.queryParmas[index + '.' + this.getEvalResultText(i.key.split('-')[0]) + '-op'] = 'eq'
            })
          }
        })
        this.propUserIds?.forEach((item, index) => {
          this.queryParmas[this.filters.length + '.ownerId' + '-' + index] = item
          this.queryParmas[this.filters.length + '.ownerId' + '-op'] = 'il'
        })
        this.queryParmas.settleId = this.settleId
        this.queryParmas.current = row.page
        const res = await postAction('/kt/task/beanSearcher', this.queryParmas)
        /*         const rest = await postAction('/factorView/kpiViewBatch', {
          taskIds: res.data.dataList?.map(item => item.taskId)
        }) */
        try {
          if (res.code == 200) {
            res.data.dataList?.forEach(item => {
              item.edit = false
              item.name = item.chapterSortAndStageName.split('-')[0]
              item.id = item.taskId
              item.editable = false
              item.taskStatus = this.getTaskStatus(item.taskStatus)
              item.taskName = item.stageName
              item.evalResult = {
                kpiValueErrors: [],
                hasKpiModifies: item.hasKpiModifies,
                kpiModifyAmount: item.kpiModifyAmount,
                textAmount: item.textAmount,
                kpiModifyRatio: item.kpiModifyRatio
              }
              for (let key in item) {
                if (key == 'errorAmount' && item.errorAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'ERROR', count: item.errorAmount })
                } else if (key == 'softAmount' && item.softAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'SOFT', count: item.softAmount })
                } else if (key == 'hardAmount' && item.hardAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'HARD', count: item.hardAmount })
                } else if (key == 'superHardAmount' && item.superHardAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'SUPER_HARD', count: item.superHardAmount })
                } else if (key == 'firstModHardAmount' && item.firstModHardAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'V1_HARD', count: item.firstModHardAmount })
                } else if (key == 'secondModHardAmount' && item.secondModHardAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'V2_HARD', count: item.secondModHardAmount })
                } else if (key == 'multipleHardAmount' && item.multipleHardAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'MORE_THAN_V2_HARD', count: item.multipleHardAmount })
                } else if (key == 'superSoftAmount' && item.superSoftAmount) {
                  item.evalResult.kpiValueErrors.push({ type: 'SUPER_SOFT', count: item.superSoftAmount })
                }
              }
            })
            /*             if (rest.data) {
              for (let key in rest.data) {
                res.data.dataList.forEach(i => {
                  if (i.taskId == key) {
                    i.totalScore = rest.data[key].totalScore
                    i.evalResult = rest.data[key]
                  }
                })
              }
            } */
            this.clearSelectKeys()
            return res.data.dataList
          }
        } catch (err) {}
      }
    },
    getTaskStatus(taskStatus) {
      let text =
        taskStatus == 'FINISHED'
          ? '已完成'
          : taskStatus == 'STARTED'
          ? '可开始'
          : taskStatus == 'WAITING'
          ? '等待'
          : taskStatus == 'REJECTING'
          ? '驳回'
          : ''
      return text
    },
    async saveUserIds() {
      const data = {
        name: 'myTaskListSearch',
        content: JSON.stringify({
          userIds: this.propUserIds,
          checkedDeadlineKeys: this.checkedDeadlineKeys,
          checkedSettlementDateKeys: this.checkedSettlementDateKeys,
          userName: this.userName,
          checkedStageNameKeys: this.checkedStageNameKeys,
          productName: this.productName,
          checkedStatusKeys: this.checkedStatusKeys,
          checkedFinishStatusKeys: this.checkedFinishStatusKeys,
          maxTotalScore: this.maxTotalScore,
          minTotalScore: this.minTotalScore,
          checkedEvalResultKeys: this.checkedEvalResultKeys,
          treeData: this.checkedEvalResultKeys ? this.treeData : [],
          filters: this.filters,
          gexpr: this.gexpr,
          groupBy: this.queryParmas.groupBy || 'stageName'
        }),
        domain: 'myTaskListSearch',
        type: 'FILTER'
      }
      await postAction('/fontQueryCondition', [data])
      this.getTableData()
    },
    async filterChangeEvent({ column, $event, values, filters }) {
      console.log(column, $event, values, filters)
      if ($event.target.innerText == '筛选') {
        if (this.filters.indexOf(column.property) == -1) {
          this.filters.push(column.property)
          this.gexpr = this.filters.length
          this.filters.forEach((i, idx) => {
            this.gexpr = this.gexpr + '&' + idx
          })
        }
        this.getTableData()
      } else {
        if (column.property == 'deadline') {
          this.checkedDeadlineKeys = []
        } else if (column.property == 'settlementDate') {
          this.checkedSettlementDateKeys = []
        } else if (column.property == 'stageName') {
          this.checkedStageNameKeys = []
        } else if (column.property == 'name') {
          this.productName = ''
        } else if (column.property == 'completeStatus') {
          this.checkedStatusKeys = []
        } else if (column.property == 'finishStatus') {
          this.checkedFinishStatusKeys = []
        } else if (column.property == 'totalScore') {
          this.minTotalScore = null
          this.maxTotalScore = null
        } else if (column.property == 'evalResult') {
          this.checkedEvalResultKeys = []
        }
        if (this.filters.indexOf(column.property) > -1) {
          this.filters.splice(this.filters.indexOf(column.property), 1)
          this.gexpr = this.filters.length
          this.filters.forEach((i, idx) => {
            this.gexpr = this.gexpr + '&' + idx
          })
        }
        this.getTableData()
      }
    },
    clearSelectKeys() {
      this.$emit('setRowkeys', [])
      this.$refs.xTree.clearCheckboxRow()
      this.selectRows = []
      this.$refs.xTree.updateFooter() //手动更新表尾
    },
    selectChangeEvent() {
      const records = this.$refs.xTree.getCheckboxRecords()
      this.$emit('setRowkeys', records)
      this.selectRows = records
      this.$refs.xTree.updateFooter() //手动更新表尾
    }
  }
}
</script>

<style lang="less" scoped>
/* /deep/ .vxe-table--fixed-wrapper .vxe-table--footer-wrapper{
  display: none !important;
} */
/deep/ .vxe-table--render-default .vxe-table--fixed-right-wrapper {
  box-shadow: none !important;
}
/deep/ .vxe-filter--btn {
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  position: relative;
  top: -2px;
}
/deep/ .filter-stageName-btn {
  color: var(--stage-color);
}
/deep/ .filter-finishStatus-btn {
  color: var(--finish-color);
}
/deep/ .filter-totalScore-btn {
  color: var(--totalScore-color);
}
/deep/ .filter-evalResult-btn {
  color: var(--evalResult-color);
}
/deep/ .filter-name-btn {
  color: var(--productName-color) !important;
}
/deep/ .filter-product-btn {
  color: var(--theme-color) !important;
}
/deep/ .filter-settlementDate-btn {
  color: var(--settlementDate-color);
}
/deep/ .filter-deadline-btn {
  color: var(--deadline-color);
}
/deep/ .filter-completeStatus-btn {
  color: var(--status-color);
}
/deep/ .vxe-filter--btn:hover {
  color: var(--theme-color);
}
.tree-page {
  position: absolute;
  width: 190px;
  left: -202px;
  top: 12px;
}
/deep/ .vxe-table--body-wrapper {
  max-height: var(--table-height) !important;
}
/* /deep/ .vxe-table--fixed-left-wrapper {
  .vxe-table--body-wrapper{
    max-height: var(--table-height) !important;
  }
} */
.review-product:hover {
  color: var(--theme-color);
  cursor: pointer;
}
.statistics {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
  &:hover {
    background: var(--back-color);
  }
}
.icon-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 12px;
  i {
    &:hover {
      cursor: pointer;
    }
  }
}
.statistics-show {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
}
.statistics-menu-list {
  opacity: 0;
  height: 0;
}
/deep/ .vxe-footer--row {
  .vxe-cell--item {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .vxe-footer--column {
    &:first-child {
      .vxe-cell--item {
        opacity: 1;
        width: 120px;
      }
    }
  }
}
/deep/ .vxe-cell--sort {
  font-size:12px !important;
  width: 1em !important;
  height: 1.35em !important;
  margin-right: 7px;
}
/deep/ .vxe-cell--filter {
  font-size: 12px !important;
  margin-top: 2px;
}
/deep/ .vxe-header--row {
  .vxe-cell {
    display: flex;
    align-items: center;
    .vxe-cell--title {
      flex: 1;
    }
  }
}
.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  .status {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
}
/deep/ .is--indeterminate .vxe-checkbox--icon::before {
  background-color: #fff !important;
}
/deep/ .vxe-checkbox--icon:hover {
  &::before {
    border-color: var(--theme-color) !important;
  }
}
/deep/ .is--checked .vxe-icon-checkbox-checked {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  font-weight: 400 !important;
}
/deep/ .vxe-table--render-default .vxe-body--row.row--current {
  background-color: var(--back-color) !important;
}
/deep/ .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--border-line {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}
/deep/ .is--filter-active .vxe-cell--filter .vxe-filter--btn {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .is--checked {
  color: var(--theme-color) !important;
}
/deep/ .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer button:hover {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer {
  display: flex;
  justify-content: space-evenly;
  button {
    &:hover {
      color: var(--theme-color);
    }
  }
}
/* /deep/ .fixed-right--wrapper .col_3{
  opacity: 0 !important;
} */
/deep/ .vxe-table--filter-wrapper {
  z-index: 9999;
}
/deep/ .is--indeterminate.vxe-table--filter-option {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--body-wrapper {
  .vxe-body--x-space {
    display: none;
  }
}
/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}
/deep/ .vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: auto !important;
}
.vxe-table--fixed-left-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/deep/ .vxe-body--column {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-table--footer-wrapper {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-header--row {
  background-color: #f8f8f9;
}
/deep/ .vxe-header--row .vxe-cell {
  font-weight: 400 !important;
}
</style>
