<template>
  <drawer-layout
    ref="drawerRef"
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
    permission="nopermission"
  >
    <template slot="other-region">
      <a-steps
        :current="statusMap[formData.status] + 1"
        size="small"
        class="mb-6"
        :status="formData.rejectType != 0 ? 'error' : 'process'"
      >
        <a-step title="自动推送" />
        <a-step :title="formData.status == 'BOSS_REJECTED' ? 'BOSS已驳回' : 'HR确认'" />
        <!-- <a-step title="BOSS已驳回" /> -->
        <a-step title="BOSS确认" />
        <a-step title="人员确认" />
        <a-step title="支付" />
      </a-steps>
    </template>

    <!--     <template slot="other-buttons">
      <a-button v-has="'statistics:rating:list'" type="primary" @click="showTaskList">查看明细</a-button>
    </template> -->
    <!-- 详情 -->
    <div class="">
      <info-row label="月份">{{ formData.month }}</info-row>
      <info-row label="人员">{{ formData.nickName }}</info-row>
      <info-row label="金额">
        {{
          formData.monies &&
            formData.monies
              .map(item => {
                return item.money + moneyTypeMap[item.moneyType]
              })
              .join('  ')
        }}
      </info-row>
      <!--       <info-row label="合作时长">{{ formData.duration ? formData.duration + '个月' : '' }}</info-row>
      <info-row label="任务数量">{{ formData.taskCount }}</info-row>
      <info-row label="平均分">{{ formData.averageScore }}</info-row>
      <info-row label="等级">{{ formData.level }}</info-row> -->
      <info-row
        label="备注"
        v-if="formData.status == 'HR_CONFIRMED' || formData.status == 'BOSS_REJECTED'"
        wrapCol="19"
      >
        <a-textarea autoSize v-model="formData.bossComment"></a-textarea>
      </info-row>
      <info-row label="历史记录">
        <a-timeline style="margin-top:6px;width: 140%;">
          <a-timeline-item style="font-size:14px" v-for="(item, index) in logList" :key="index"
            >（{{ item.operationTime }}）{{ item.userName }}{{ ' ' }} {{ item.content }}</a-timeline-item
          >
        </a-timeline>
      </info-row>
      <div style="text-align: right">
        <a-button v-has="'statistics:settlement:reject'" type="danger" class="mr-3" @click="rejectRating"
          >驳回</a-button
        >
        <a-button v-has="'statistics:settlement:confirm'" type="primary" @click="confirmRating">确认</a-button>
        <!--         <template v-else-if="userInfo.roles.every(item => item.roleCode != 'tongchou')">
          <a-button
            v-has="'statistics:rating:confirm'"
            :disabled="formData.status != 'HR_CONFIRM' && userInfo.roles.some(item => item.roleCode == 'hr')"
            type="primary"
            @click="confirmRating"
            >确认</a-button
          >
        </template> -->
      </div>
    </div>
    <!-- 表单 -->
    <div slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="月份" prop="month">
          <a-input v-model="formData.month" placeholder="请输入月份" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="人员" prop="nickName">
          <a-input v-model="formData.nickName" placeholder="请输入人员" :disabled="true"></a-input>
        </a-form-model-item>
        <!--         <a-form-model-item label="职位" prop="positionName">
          <a-input v-model="formData.positionName" placeholder="请输入职位" :disabled="true"></a-input>
        </a-form-model-item> -->
        <a-form-model-item label="金额" prop="productionCount">
          <a-input-number v-model="formData.money" placeholder="请输入金额"></a-input-number>
        </a-form-model-item>
        <!--         <a-form-model-item label="任务数量" prop="taskCount">
          <a-input v-model="formData.taskCount" placeholder="请输入任务数量"></a-input>
        </a-form-model-item>
        <a-form-model-item label="平均分" prop="averageScore">
          <a-input v-model="formData.averageScore" placeholder="请输入任务数量"></a-input>
        </a-form-model-item>
        <a-form-model-item label="等级" prop="level">
          <a-select v-model="formData.level">
            <a-select-option value="专家">专家</a-select-option>
            <a-select-option value="高级">高级</a-select-option>
            <a-select-option value="中级">中级</a-select-option>
            <a-select-option value="低级">低级</a-select-option>
            <a-select-option value="新人">新人</a-select-option>
          </a-select>
        </a-form-model-item> -->
      </a-form-model>
    </div>

    <div v-if="showScoreDetail">
      <g-title>查看明细</g-title>
      <!--       <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="taskList"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 800, y: 500 }"
      >
      </a-table> -->
      <settlement-table
        :month="formData.month"
        :settleId="formData.id"
        :taskIds="formData.taskIds ? formData.taskIds.split(',') : []"
      />
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { getAction, postAction } from '@/api/manage'
import settlementTable from '@/components/settlement-table'
import { simpleDebounce } from '@/utils/util'
export default {
  components: {
    settlementTable
  },
  mixins: [DrawerMixins],
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      logList: [],
      rules: {
        money: [{ required: true, message: '请输入金额', trigger: 'blur' }]
        /*         taskCount: [{ required: true, message: '请输入任务数量', trigger: 'blur' }],
        averageScore: [{ required: true, message: '请输入平均分', trigger: 'blur' }],
        level: [{ required: true, message: '请输入等级', trigger: 'blur' }] */
      },
      showScoreDetail: false,
      url: {
        edit: '/rating/edit'
      },
      moneyTypeMap: {
        CNY: '元',
        TWD: '台币',
        JPY: '円',
        USD: '美元'
      },
      taskList: [],
      loading: false,
      statusMap: {
        AUTO_GENERATE: 0,
        HR_CONFIRMED: 1,
        BOSS_REJECTED: 1,
        BOSS_CONFIRMED: 2,
        USER_CONFIRMED: 3,
        BOSS_PAYED: 4
      },
      columns: [
        {
          title: '章节序号',
          dataIndex: 'chapterSequenceNo',
          width: 100
        },
        {
          title: '数量',
          dataIndex: 'pageCount',
          width: 100
        },
        {
          title: '人员',
          dataIndex: 'user.userName',
          width: 100
        },
        {
          title: '截止日期',
          dataIndex: 'deadline',
          width: 120
        },
        {
          title: '结算日期',
          dataIndex: 'settlementDate',
          width: 120
        },
        {
          title: '考核结果',
          dataIndex: 'evalResult',
          width: 200
        },
        {
          title: '考核得分',
          dataIndex: 'evalScore'
        }
      ]
    }
  },
  methods: {
    initForm() {
      this.formData = {
        comment: ''
      }
    },
    async show() {
      this.formData = this.curRow
      if (this.formData.rejectType == 1) {
        this.formData.comment = this.formData.bossComment
      } else if (this.formData.rejectType == 2) {
        this.formData.comment = this.formData.staffComment
      }
      this.isEdit = this.isEditForm
      this.taskList = []
      this.showScoreDetail = false
      this.$refs.drawerRef.showFullScreen(this.showScoreDetail)
      const res = await postAction('/log/list/listSettleHistory', { taskIds: this.curRow.taskIds, id: this.curRow.id })
      if (res.code == 200) {
        this.logList = res.data
      }
    },
    showTaskList() {
      this.showScoreDetail = !this.showScoreDetail
      this.$refs.drawerRef.showFullScreen(this.showScoreDetail)
      /*       if (this.showScoreDetail && this.taskList.length == 0) {
        this.loading = true
        getAction('/rating/get_tasks?ids=' + this.formData.taskIds).then(({ success, data }) => {
          if (success) {
            this.taskList = data
          }
          this.loading = false
        })
      } */
    },

    confirmRating() {
      postAction(
        '/kt/settle/confirm?ids[0]=' + this.formData.id + '&bossComment=' + (this.formData.bossComment || '')
      ).then(res => {
        if (!res.success) {
          this.$message.error(res.msg)
        } else {
/*           if (res.data && res.data.length) {
            this.$emit('modalGetPdf',res)
          } */
        }
        this.$emit('closed', true)
      })
    },
    rejectRating: simpleDebounce(async function() {
      this.rejectRatingSim()
    }, 500),
    rejectRatingSim() {
      let taskIds = this.formData.taskIds
      let ids = [this.formData.id]
      postAction('/kt/settle/reject', { dataIds: ids, taskIds }).then(({ success, msg }) => {
        if (!success) {
          this.$message.error('驳回成功')
        }
        this.$emit('closed', true)
      })
    },

    setFormData() {
      delete this.formData.month
    }
  },
  created() {
    this.initForm()
  }
}
</script>

<style lang="less" scoped></style>
